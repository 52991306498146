import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { promotionList } from '../types';

export const getPromotionList = async (): Promise<[]> => {
  const response = await axios.get(`/api/v1/admin/referCoupan`, {
})
  //return response?.data;
   const data = response.data;

  return data;
};

type QueryFnType = typeof getPromotionList;

type PromotionListOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const usePromotionList = ({  config }: PromotionListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getPromotionList(),
    ...config,
  });
};