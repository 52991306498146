import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import lotteryspinner from "@/assets/lotteryspinner.svg";
import RecentActivities from "@/features/Dashboard/components/recentActivities";
import Logo from "@/assets/tableLogo.svg";
import readme from "@/assets/readme.svg";
import { theme } from "@/components/Elements/theme";
import { useNavigate } from "react-router-dom";
import UpcomingLotteryCrousel from "@/features/Homescreen/Components/upcomingLotteryCrousel";
import UpComingLotteryDraws from "@/features/Homescreen/Components/upcomingLotteryDraws";
import WinningHistory from "@/features/Homescreen/Components/winningHistory";
import { useLotteries } from "../api/getDashboardList";
import Countdown from "react-countdown";
import { any } from "zod";
import Timer from "./Timer";
import TableDynamic from "./TableDynamic";
import HomeScreenImg from "@/assets/earth.jpg";
import yellowt from "@/assets/yellow-t.png";
import whitet from "@/assets/white-t.png";

const useStyles = makeStyles((_theme) => ({
  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "30px",
    },
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridFirst: {
    background: `url(${lotteryspinner})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "left bottom",
    [theme.breakpoints.down("sm")]: {
      minHeight: "200px",
      minWidth: "200px",
    },
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  tableContainer: {
    background: "#050518",
    borderRadius: 8,
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    color: "#fff",
  },
  tableData: {
    fontSize: "14px",
    color: "#fff",
    opacity: "0.6",
    borderBottom: "1px solid #0D0D39",
  },
  logo: {
    width: 50,
  },
}));

const rows = [
  {
    id: 1,
    jackpot: "First Draw Prize",
    amount: "$100,000",
  },
  {
    id: 2,
    jackpot: "2nd Draw Prize",
    amount: "$75,000",
  },
  {
    id: 3,
    jackpot: "3rd Draw Prize",
    amount: "$45,000",
  },
  {
    id: 4,
    jackpot: "4th Draw Prize",
    amount: "$30,000",
  },
];

export const DashboardMain = () => {
  const classes = useStyles();
  const LotteryQuery = useLotteries({});
  const upcomingArray = LotteryQuery?.data;


  if (LotteryQuery.status === "success") {
    let arr1 = LotteryQuery?.data;

  }

  // ("upCommingLotteries");
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  // if (LotteryQuery.isLoading) {
  //   return (
  //     <>
  //       <CircularProgress />
  //     </>
  //   );
  // }

  return (
    <>
      <Box
        sx={{
          padding: { md:"150px 30px 30px 30px" , xs: "150px 10px 30px 10px" },
          background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          // paddingTop: "70px !important"
          height: "100%",
          minHeight:"calc(100vh - 415px)"
        }}
      >
        <Grid
          sx={{ width: "100%", mx: "auto" }}
          container
          spacing={3}
          justifyContent="space-around"
          className={classes.myGridItem}
        >
          <Grid 
            item
            xs={12}
            sm={6}
            className={classes.innerGrid}
          >
            <Grid container>
              <Timer dataRecent={LotteryQuery?.data} />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            className={classes.innerGrid} 
          >
            <TableDynamic dataRecent={LotteryQuery?.data} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Box>
              <RecentActivities dataRecent={LotteryQuery?.data} />
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Box>
              <UpcomingLotteryCrousel dataRecent={LotteryQuery?.data} />
            </Box>
          </Grid>
        </Grid>
        {/* <Grid container><Grid item xs={12}><Box><UpComingLotteryDraws  dataRecent={LotteryQuery?.data} /></Box></Grid></Grid> */}
        <Grid container>
          <Grid item xs={12}>
            <Box>
              <WinningHistory/>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DashboardMain;
