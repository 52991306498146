/* eslint-disable react-hooks/rules-of-hooks */
import { axios } from '@/lib/axios';

import { friendsList } from '../types';
import { useNotificationStore } from '@/stores/notification';
import { MutationConfig } from '@/lib/react-query';
import { useMutation } from 'react-query';
import Swal from 'sweetalert2';
export type postFriendsDTO = {
  email: string;
  message?: string;
};

export const postFriendsRefer = async (data: postFriendsDTO) => {
  const dataValue:any = await axios.post('/api/v1/user/referfriend', data);

  if (dataValue?.data === "Referral link send ") {
    Swal.fire({
      background: "black",
      color: "#ffff",
      icon: 'success',
      title: 'Success!',
      text: 'Referral coupon code sent successfully',
    })
  }
  else {
    Swal.fire({

      icon: 'error',
      background: "black",
      color: "#ffff",
      title: dataValue?.message,
      text: 'check your mail Id!!'
    });
  }
  return dataValue.data;
};

type postFriendsReferOptions = {
  data: {
    email: String;
  };
  config?: MutationConfig<typeof postFriendsRefer>;
};

export const usePostReferFriend = ({ data, config }: postFriendsReferOptions) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    mutationFn: postFriendsRefer,
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Form submitted successfully.',
      });
    },
    ...config,
  });
};
