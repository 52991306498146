import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import clsx from "clsx";
import "sweetalert2/dist/sweetalert2.min.css";
import HomeScreenImg from "@/assets/earth.jpg";
import Logo from "@/assets/tableLogo.svg";
import { theme } from "@/components/Elements/theme";
import Notes from "./Notes";
import CurrencyFormat from "react-currency-format";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import StaticFAQ from "@/features/Faq/components/staticFAQ";
import trophy from "@/assets/trophy.png";
import { useAuth } from "@/lib/auth";
import { getLotteryCountById, useLotteryById } from "../api/getLotteryById";
import {
  lotteryDataValue,
  lotteryDataDTO,
  lotteryDataFnData,
} from "../api/postLottery";
import { useParams, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import Countdown from "react-countdown";
import yellowt from "@/assets/yellow-t.png";
import whitet from "@/assets/white-t.png";
import "../../../components/Elements/CSS/style.css";
import { useLotteryCountById } from "../api/getLotteryCount";
import { axios } from "@/lib/axios";
import storage from "@/utils/storage";
import {
  yellow,
  blue,
  red,
  orange,
  green,
  brown,
  pink,
  purple,
} from "@mui/material/colors";
import { Padding } from "@mui/icons-material";
import moment from "moment";

interface SpinningBallData {
  _id: string;
  endDate: string;
  endTime: string;
  lotteryDrawDate: string;
  randomGeneratedBall: number;
  availableBalls: number[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: "40px",
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    boxSizing: "border-box",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "25px",
    },
  },
  first: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
    paddingBottom: "20px !important",
    [theme.breakpoints.down("sm")]: {
      minHeight: "295px",
      paddingBottom: "10px !important",
    },
  },
  second: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 150,
  },
  parentPaper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 1600,
  },
  availableTickets: {
    border: "0.5px solid rgba(255, 255, 255, 0.2)",
    borderRadius: "10px",
    height: 76,
    [theme.breakpoints.down("md")]: {
      height: "52px",
    },
  },
  noOfTickets: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    marginBlock: "25px",
    [theme.breakpoints.down("md")]: {
      marginBlock: "20px",
    },
  },
  tableContainer: {
    background: "#050518",
    borderRadius: 8,
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    color: "#fff",
    padding: "22px !important",
    [theme.breakpoints.down("lg")]: {
      padding: "18px !important",
    },
    [theme.breakpoints.down("md")]: {
      padding: "15px !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px !important",
    },
  },
  logoTable: {
    [theme.breakpoints.down("md")]: {
      height: "45px",
      width: "45px",
    },
  },
  tableData: {
    fontSize: "14px",
    color: "#fff",
    opacity: "0.6",
    BorderBottom: "1px solid #0D0D39",
  },
  minimumTicket: {
    background: "#3C37FF",
    borderRadius: "10px",
    color: "#fffff",
  },
  logo: {
    width: 50,
  },
  myGridItem: {
    // backgroundColor: "rgba(5, 5, 24, 0.8)",
    backgroundColor: "rgb(75 75 75 / 80%)",
    padding: "30px",
    [theme.breakpoints.down("md")]: {
      padding: "10px !important",
    },
    [theme.breakpoints.up("md")]: {
      padding: "30px !important",
    },
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px !important",
    },
  },
  innerGridFirst: {
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    maxHeight: "282px",
    borderRadius: "20px",
    maxWidth: "100%",
    marginInline: "auto",
    backgroundPosition: "left bottom",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.down("md")]: {
      borderRadius: "10px",
    },
  },
  substract: {
    border: "1px solid #ffff !important",
    borderRadius: "10px !important",
    color: "#ffff",
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
    [theme.breakpoints.down("md")]: {
      padding: "7px",
    },
  },
  ticketPrize: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    padding: "30px",
    [theme.breakpoints.down("md")]: {
      padding: "5px 10px",
    },
  },

  ballContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: theme.spacing(1),
  },
  cardContent: {
    position: "relative",
    padding: theme.spacing(2),
  },
  ballRow: {
    display: "flex",

    margin: theme.spacing(-1),
  },
  ball: {
    width: 21, // Adjust width of the ball
    height: 21, // Adjust height of the ball
    fontSize: 11, // Adjust font size of the ball number
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    borderRadius: "50%",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
    margin: theme.spacing(-0.5),
    backgroundImage: (props: any) =>
      `linear-gradient(135deg, ${props.color} 30%, ${props.colorDark} 90%)`,
    transition: "transform 0.3s ease-in-out", // Transition for animation
    "&:hover": {
      transform: "scale(1.9)", // Scale up on hover
    },
  },
  selectedBall: {
    transform: "scale(2.0)",
    border: "2px solid white",
    "@media screen and (max-width:992px)": {
      transform: "scale(1.5)",
    },
  },
  ballText: {
    marginTop: theme.spacing(1),
    fontSize: 12,
  },

  checkboxLabel: {
    fontSize: "12px",
    color: "#ffffff",
    "& .MuiSvgIcon-root": {
      fontSize: "16px",
      fill: "#ffffff",
    },
    "& .MuiTypography-body1": {
      fontSize: "12px",
    },
  },
}));

const PrettoSlider = styled(Slider)({
  color: "#3C37FF",
  height: 2,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 10,
    width: 10,
    backgroundColor: "#fff",
    border: "10px solid rgba(255, 255, 255, 0.5)",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "10% 10% 10% 10",
    backgroundColor: "#3C37FF",
    transformOrigin: "bottom left",
  },
});

const dataValue = {
  heading: "Buy Ticket",
  subHeading: "",
};

type ticketValues = {
  price: number;
  ticketNumber: number;
  speed: number;
  deceleration: number;
  no_of_token: number;
  type: string;
  redeemCode: String;
  freeTicketAmt: number;
  couponId: string
};



export default function BuyNow() {
  const classes = useStyles({});
  const { user } = useAuth();
  const { id } = useParams();
  const LotteryQuery = useLotteryById({ id });
  const lotteryData = LotteryQuery?.data?.data;
  console.log(lotteryData, "lotteryData");
  const [totalSoldTicket, settotalSoldTicket] = useState(0);
  const [jackpotSelected, setJackpotSelected] = useState(false);
  const [LotteryData, setLotteryData] = useState("");
  const [selectedBall, setSelectedBall] = useState(null);
  const [searchParams] = useSearchParams();
  const redeemCode = searchParams.get("redeemCode")
  const freeTicketAmt = searchParams.get("freeTicketAmt")
  const couponId = searchParams.get("CouponId");
  console.log(couponId, "couponId");
  const initialValues: ticketValues = {
    price: 0,
    ticketNumber: 0,
    speed: 0,
    deceleration: 0,
    no_of_token: 0,
    type: "",
    redeemCode: redeemCode ? redeemCode : "",
    freeTicketAmt: freeTicketAmt ? Number(freeTicketAmt) : 0,
    couponId: couponId ? couponId : ""
  };
  let availableTickets =
    (lotteryData?.numOfTicketOnSale || 0) - totalSoldTicket;

  useEffect(() => {
    getData();
    availableTickets = (lotteryData?.numOfTicketOnSale || 0) - totalSoldTicket;
  }, []);

  useEffect(() => {
    const getBallData = async () => {
      let token = storage.getToken();

      try {
        const res = await axios.get(
          `${process.env.API_URL_PREFIX}/api/v1/admin/fetchSpinningBalls/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        setSpinningBallData(res.data[0]);
      } catch (err) {
        console.log(err);
      }
    };

    getBallData();
  }, [id]);

  useEffect(() => {
    const getLotteryData = async () => {
      let token = storage.getToken();

      try {
        const res = await axios.get(
          `${process.env.API_URL_PREFIX}/api/v1/user/lotteryDetailById/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
              TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          }
        );
        setLotteryData(res.data.jackpotSelected);
      } catch (err) {
        console.log(err);
      }
    };

    getLotteryData();
  }, [id]);

  const getData = async () => {
    let token = storage.getToken();
    // let formData
    // useLotteryTicketList({lotteryId:"649d4943c0a0d39915560799"})

    await axios
      .get(`${process.env.API_URL_PREFIX}/api/v1/user/ticketcount/` + id, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        settotalSoldTicket(res.data[0].totalSoldLottery);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const [formValues, setFormValues] = useState<ticketValues>(initialValues);
  console.log(formValues , "formValues")
  const [count, setCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  console.log(totalAmount);
 
  const [jackpotAmount, setJackpotAmount] = useState(0);
  const [InputValue, setInputValue] = useState(3);
  const [SpeedValue, setSpeedValue] = useState(60);
  const [isLoading, setIsLoading] = useState(false);
  const countDownDate = new Date(lotteryData?.endDate || 0).getTime();
  const [countDown, setCountDown] = useState(
    new Date().getTime() - countDownDate
  );
  const [spinningBallData, setSpinningBallData] =
    useState<SpinningBallData | null>(null);

  const sortedAvailableBalls = spinningBallData
    ? [...spinningBallData.availableBalls]
        .filter((ball) => ball !== undefined)
        .sort((a, b) => a - b)
    : [];

  console.log(sortedAvailableBalls, "Sorted Available Balls");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const totalPay = totalAmount + jackpotAmount;

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const ticketPrice: any = lotteryData?.ticketPrice || 0;
  const updateAmounts = (tokenCount, isJackpotSelected) => {
    const baseAmount: any = tokenCount * ticketPrice;
    const jackpotAmount = tokenCount * 0.5;

    setJackpotAmount(isJackpotSelected ? jackpotAmount : 0);
    setTotalAmount(baseAmount);
  };

  useEffect(() => {
    updateAmounts(formValues.no_of_token, jackpotSelected);
  }, [formValues.no_of_token, jackpotSelected]);

  const handleCount = (increment) => {
    const newTokenCount = formValues.no_of_token + increment;

    if (newTokenCount <= availableTickets && newTokenCount >= 0) {
      setCount((prevCount) => prevCount + increment);
      setFormValues((prevValues) => ({
        ...prevValues,
        no_of_token: newTokenCount,
      }));
    } else {
      Swal.fire({
        icon: "error",
        text: "You can select up to Available Ticket",
        color: "#fff",
        background: "black",
      });
    }
  };
  const handleAddOne = () => handleCount(1);

  const handleSubtractOne = () => handleCount(-1);

  const handleAddTen = () => handleCount(10);

  const handleSubtractTen = () => handleCount(-10);

  var sweet_loader =
    '<div class="circular-progress"><div class="circle"></div><div class="mask full"><div class="fill"></div></div><div class="mask half"><div class="fill"></div><div class="fill fix"></div></div></div>';
  const showLoadingPopup = () => {
    Swal.fire({
      title: "We are processing your purchase, one moment please",
      allowOutsideClick: false,

      showCancelButton: false,
      html: sweet_loader,
      customClass: "buy-now-pop-up",
      background: "black",
      color: "#ffff",
      showLoaderOnConfirm: true,
      timer: 1000000,
      showConfirmButton: false,
      timerProgressBar: true,
    }).then(
      function () {},
      // handling the promise rejection
      function (dismiss) {
        if (dismiss === "timer" && !isLoading) {
        }
      }
    );
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormValues({
      ...formValues,
      speed: SpeedValue,
      deceleration: InputValue,
    });
    let data = {
      speed: SpeedValue,
      deceleration: InputValue,
      numOfToken: formValues.no_of_token,
      type: "crypto",
      redeemCode: redeemCode ? redeemCode : "",
      freeTicketAmt: freeTicketAmt ? Number(freeTicketAmt) : 0,
      jackpotStatus: jackpotSelected,
      selectedJackpotColourNumber: selectedBall || "",
      couponId : couponId ? couponId : "" 
    };

    let idData: any = lotteryData?._id;
    handleLotteryDataValue(data, idData);
  };
  const generateLotteryAvailableTicket = () => {
    let num: any = lotteryData?.numOfTicketOnSale || 0;
    let num2 = lotteryData?.numOfTicketSold || 0;
    let num3 = num - num2;
    return num3;
  };
  const handleLotteryDataValue = async (
    data: {
      speed: number;
      deceleration: number;
      numOfToken: number;
      type: string;
      jackpotStatus: Boolean;
      selectedJackpotColourNumber: string;
    },
    idData: any
  ) => {
    try {
      setIsLoading(true);
      // Make the API request using lotteryDataValue function
      await lotteryDataValue({
        data,
        idValue: idData,
        message: "Purchased Lottery Successfully",
      }).then((res) => {
        Swal.fire({
          background: "black",
          color: "#ffff",
          icon: "success",
          title: "Success!",
          text: res.message,
        }).then(() => {
          window.location.assign("/app/liveLotteries");
        });
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        background: "black",
        color: "#ffff",
        text: `${error?.response?.data?.message}`,
      });
    }
  };

  if (!LotteryQuery?.data) return <h4>No List Found</h4>;
  const heightStyles = {
    xs: "1vw",
    md: "1.3vw",
  };
  const sendBallNumber = (number) => {
    console.log(number, "number");
  };

  const handleBallClick = (number) => {
    setSelectedBall((prevSelectedBall) => {
      // Toggle selection based on the current state
      if (prevSelectedBall === number && jackpotSelected) {
        setJackpotSelected(false); // Deselect the checkbox when deselecting the ball
        return null; // Deselect the ball
      } else {
        setJackpotSelected(true); // Enable jackpot when selecting a ball
        return number; // Select the clicked ball
      }
    });
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setJackpotSelected(isChecked);
    updateAmounts(formValues.no_of_token, isChecked);
    if (!e.target.checked) {
      setSelectedBall(null); // Deselect ball when jackpot is unchecked
    }
  };

  const colors = [
    { light: yellow[700], dark: yellow[900] },
    { light: blue[700], dark: blue[900] },
    { light: red[700], dark: red[900] },
    { light: pink[900], dark: pink[800] },
    { light: orange[700], dark: orange[900] },
    { light: green[700], dark: green[900] },
    { light: purple[700], dark: purple[900] },
  ];

  const Ball = ({ number, color, colorDark, isSelected, onClick }) => {
    const ballStyles = useStyles({});

    return (
      <Box display="flex" flexDirection="column" alignItems="center" margin={1}>
        <Box
          className={`${ballStyles.ball} ${
            isSelected ? ballStyles.selectedBall : ""
          }`}
          style={{ backgroundColor: isSelected ? colorDark : color }}
          onClick={() => onClick(number)}
        >
          {number}
        </Box>
      </Box>
    );
  };

  const BallRow = ({ selectedBall, onBallClick, availableBalls }) => {
    return (
      <Box className={useStyles({}).ballRow}>
        {availableBalls &&
          availableBalls?.map((ballNumber, index) => (
            <Ball
              key={index}
              number={ballNumber}
              color={colors[ballNumber - 1]?.light}
              colorDark={colors[ballNumber - 1]?.dark}
              isSelected={selectedBall === ballNumber}
              onClick={onBallClick}
            />
          ))}
      </Box>
    );
  };

  // const BallRow = ({ selectedBall, onBallClick, availableBalls }) => {
  //   return (
  //     <Box className={useStyles({}).ballRow}>
  //       {availableBalls &&
  //         availableBalls?.map((ballNumber, index) => {
  //           const colorData = colors[ballNumber - 1];
  //           if (!colorData) {
  //             console.error(`Invalid ball number: ${ballNumber}`);
  //             return null;
  //           }
  //           return (
  //             <Ball
  //               key={index}
  //               number={ballNumber}
  //               color={colorData.light}
  //               colorDark={colorData.dark}
  //               isSelected={selectedBall === ballNumber}
  //               onClick={onBallClick}
  //             />
  //           );
  //         })}
  //     </Box>
  //   );
  // };

  return (
    <Box
      sx={{
        background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100%",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        paddingTop: { xs: "65px", md: "105px" },
      }}
    >
      <Box
        sx={{ backgroundColor: "rgba(5, 5, 24, 0.8)", borderRadius: "10px" }}
      />
      <Box sx={{ padding: { md: theme.spacing(0.5), xs: theme.spacing(0.2) } }}>
        {isLoading && <>{showLoadingPopup()}</>}
        <div className={classes.root}>
          <Grid
            sx={{ width: "100%", mx: "auto", columnGap: "20px" }}
            container
            spacing={3}
            justifyContent="space-around"
            // justifyContent="space-around"
            className={classes.myGridItem}
          >
            <Grid
              sx={{
                p: { xs: "0px !important", md: "0 px!important" },
                mb: { xs: "20px !important", md: "0 !important" },
                display: "flex",
              }}
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              className={classes.innerGrid}
            >
              <Grid
                container
                className="Lotteries-buy-page"
                style={{
                  margin: "auto",
                  flexFlow: "row wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                spacing={2}
              >
                <Grid item xs={12} sm={6} md={5} lg={5}>
                  <Box style={{ position: "relative", textAlign: "center" }}>
                    <img
                      className={classes.innerGridFirst}
                      alt="lottery-name"
                      // eslint-disable-next-line no-template-curly-in-string
                      src={`${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${lotteryData?.image}`}
                    ></img>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={7}
                  lg={7}
                  className={classes.innerGridSecond}
                >
                  <Grid container sx={{ mt: { md: "21px", xs: "5px" } }}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "x-large",
                      }}
                    >
                      <Typography
                        variant="caption"
                        align="center"
                        sx={{
                          textTransform: "uppercase",
                          color: "white",
                          fontSize: "2vw",
                          fontWeight: 700,
                        }}
                      >
                        {lotteryData?.lotteryName
                          ? lotteryData?.lotteryName
                          : ""}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography
                        variant="caption"
                        align="center"
                        sx={{ color: "white" }}
                      >
                        <span
                          style={{
                            fontSize: "1.8vw",
                            fontWeight: 700,
                            height: "10px",
                          }}
                        >
                          {" "}
                          Ticket Price :{" "}
                        </span>
                        <img
                          style={{ height: heightStyles.xs }}
                          src={whitet}
                          alt="currency"
                        />{" "}
                        <span
                          style={{
                            fontSize: "1.5vw",
                            fontWeight: 700,
                            height: "10px",
                          }}
                        >
                          {lotteryData?.ticketPrice
                            ? lotteryData?.ticketPrice
                            : " "}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h3"
                        align="center"
                        sx={{
                          color: "#FFBC00",
                          mt: { xs: "1.5vw", md: "1.5vw" },
                          fontSize: {
                            xs: "12px",
                            sm: "14px",
                            md: "14px",
                            lg: "16px",
                          },
                          lineHeight: "1.5",
                        }}
                      >
                        DRAW DATE :{" "}
                        {moment(lotteryData?.lotteryDrawDate).format(
                          "DD-MM-YYYY"
                        )}
                        <br />
                        DRAW TIME : {`${lotteryData?.lotteryDrawTime} `}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mb={3.5}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        align="center"
                        sx={{
                          fontWeight: "700",
                          fontSize: {
                            xs: "25px !important",
                            md: "32px !important",
                            lg: "45px !important",
                          },
                          wordBreak: "break-word",
                        }}
                      >
                        <Countdown
                          date={
                            new Date(
                              `${lotteryData?.endDate}T${lotteryData?.endTime}`
                            )
                          }
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        align="center"
                        sx={{ fontSize: "20px", fontWeight: "700" }}
                      >
                        Total tickets :
                        <CurrencyFormat
                          value={lotteryData?.numOfTicketOnSale}
                          thousandSeparator={","}
                          displayType={"text"}
                          decimalSeparator={"."}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              lg={5}
              className={classes.innerGrid}
              sx={{ mb: { xs: 2.5, md: 0 } }}
            >
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
                sx={{
                  maxHeight: { xs: "300px !important", sm: "255px" },
                  minHeight: "100%",
                }}
              >
                <Table
                  className={classes.table}
                  aria-label="custom table"
                  sx={{
                    p: { xs: "10px", sm: "25px" },
                    minWidth: "340px !important",
                  }}
                >
                  <TableHead sx={{ backgroundColor: "#0D0D36 !important" }}>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        <img
                          src={Logo}
                          alt="table logo"
                          className={classes.logoTable}
                        />
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          sx={{
                            color: "#FFFFFF",
                            p: {
                              sm: "0px !important",
                              md: "0px !important",
                              lg: "0px !important",
                            },
                            textAlign: "center",
                            fontSize: "14px",
                          }}
                        >
                          Total Prize-pool :
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        <Typography
                          variant="h1"
                          gutterBottom
                          sx={{
                            color: "#FFFFFF",
                            p: {
                              sm: "16px !important",
                              md: "0px !important",
                              lg: "0px !important",
                            },
                            textAlign: "end",
                            float: "right",
                            display: "flex",
                          }}
                        >
                          <img
                            style={{
                              height: "auto",
                              width: "24px",
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginRight: "6px",
                            }}
                            src={whitet}
                          />
                          {/* {lotteryData?.drawPrize.reduce(
                            (acc, curr) => acc + parseInt(curr),
                            0
                          )} */}
                          <CurrencyFormat
                            value={lotteryData?.drawPrize.reduce(
                              (acc, curr) => acc + parseInt(curr),
                              0
                            )}
                            thousandSeparator={","}
                            displayType={"text"}
                            decimalSeparator={"."}
                          />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lotteryData?.drawPrize?.map((row, index) => (
                      <TableRow key={index + 1}>
                        <TableCell
                          colSpan={2}
                          className={classes.tableData}
                          sx={{ p: "4px" }}
                        >
                          <ul style={{ margin: "0px" }}>
                            <li>{index + 1 + "th spin"}</li>
                          </ul>
                        </TableCell>
                        {/* <TableCell className={classes.tableData}></TableCell> */}
                        <TableCell
                          className={classes.tableData}
                          sx={{ textAlign: "center", p: "4px" }}
                        >
                          <span>
                            <img
                              alt="yellow dolar"
                              style={{
                                height: "auto",
                                width: "17px",
                                marginRight: "4px",
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                              src={yellowt}
                            />
                            {/* {lotteryData?.drawPrize[index]} */}
                            <CurrencyFormat
                              value={lotteryData?.drawPrize[index]}
                              thousandSeparator={","}
                              displayType={"text"}
                              decimalSeparator={"."}
                            />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Box sx={{ mt: { xs: "20px", sm: "30px", md: "40px" } }}>
            <Grid container direction="row" spacing={{ xs: 2, md: 4 }}>
              <Grid item xs={12} md={4}>
                <Paper
                  className={clsx(classes.first, classes.paperClass)}
                  sx={{
                    p: {
                      xs: "20px 18px !important",
                      md: "40px 36px !important",
                    },
                    //height: "500px !important", // Ensuring paper adjusts its height based on content
                    //overflow: "hidden", // Prevent content from overflowing
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid
                        container
                        className={classes.availableTickets}
                        sx={{ p: { xs: "12px", md: "20px" } }}
                        alignItems="center"
                      >
                        <Grid item xs={6} sm={6} lg={6}>
                          <Typography
                            variant="subtitle2"
                            textAlign="left"
                            sx={{ fontWeight: "700", lineHeight: 1 }}
                          >
                            Price :
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} lg={6}>
                          <Typography
                            variant="h4"
                            sx={{ fontWeight: "700", lineHeight: 1 }}
                            textAlign="right"
                          >
                            {lotteryData?.ticketPrice}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Box className={classes.noOfTickets}>
                    <Typography
                      variant="h5"
                      textAlign="center"
                      sx={{ marginTop: "20px" }}
                    >
                      Select No. of Tickets:
                    </Typography>
                    <Stack
                      flexDirection="column"
                      alignItems="center"
                      sx={{ mt: "13px" }}
                    >
                      <ButtonGroup sx={{ mb: "11px" }}>
                        <Button
                          className={classes.substract}
                          onClick={handleSubtractOne}
                        >
                          <RemoveIcon sx={{ fontSize: "15px" }} />
                        </Button>
                        <Button
                          className={classes.minimumTicket}
                          sx={{
                            borderRadius: "10px !important",
                            color: "#ffff",
                            mx: "12px !important",
                          }}
                        >
                          {" "}
                          <span style={{ color: "#ffff !important" }}>
                            {formValues.no_of_token || 1}
                          </span>
                        </Button>
                        <Button
                          className={classes.substract}
                          onClick={handleAddOne}
                        >
                          <AddIcon sx={{ fontSize: "15px" }} />
                        </Button>
                      </ButtonGroup>
                      <ButtonGroup>
                        <Button
                          className={classes.substract}
                          onClick={handleSubtractTen}
                        >
                          <RemoveIcon sx={{ fontSize: "15px" }} />
                        </Button>
                        <Button
                          sx={{
                            borderRadius: "10px !important",
                            mx: "12px !important",
                            background: "#ffff !important",
                          }}
                          disabled
                        >
                          <span style={{ color: "#ffff !important" }}>
                            {formValues.no_of_token || 10}
                          </span>
                        </Button>
                        <Button
                          className={classes.substract}
                          onClick={handleAddTen}
                        >
                          <AddIcon sx={{ fontSize: "15px" }} />
                        </Button>
                      </ButtonGroup>
                    </Stack>
                  </Box>

                  {LotteryData && (
                    <Grid container alignItems="center" spacing={3}>
                      {/* Checkbox Grid Item */}
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={
                            <Checkbox
                              sx={{
                                color: "#ffffff",
                              }}
                              checked={jackpotSelected}
                              onChange={handleCheckboxChange}
                              id="jackpotSelected"
                            />
                          }
                          label="Enable Jackpot"
                        />
                      </Grid>

                      {/* Balls Grid Item */}
                      <Grid item xs={12} sm={6}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "15px",
                            marginBottom: "25px",
                            "& div ": {
                              gap: "10px",
                              flexFlow: "row wrap",
                            },
                            "& div div": {
                              "&:hover": {
                                cursor: "pointer",
                              },
                            },
                          }}
                        >
                          <BallRow
                            selectedBall={selectedBall}
                            onBallClick={handleBallClick}
                            availableBalls={sortedAvailableBalls}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  )}

                  <Grid container>
                    <Grid item xs={12}>
                      <Grid
                        container
                        className={classes.availableTickets}
                        sx={{
                          p: { xs: "10px", md: "20px" },
                          maxHeight: "70px",
                          display: "flex",
                          marginBottom: "20px",
                        }}
                      >
                        <Grid
                          item
                          xs={8}
                          md={8}
                          sx={{
                            maxHeight: "20px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        >
                          {" "}
                          <Typography
                            variant="subtitle2"
                            textAlign="left"
                            sx={{ lineHeight: "normal" }}
                          >
                            Total Tickets{" "}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          md={4}
                          sx={{
                            maxHeight: "20px",
                            display: "flex",
                            margin: "auto",
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: "700",
                              margin: "auto",
                              lineHeight: "0.1",
                            }}
                            textAlign="right"
                          >
                            {formValues.no_of_token}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid container spacing={{ xs: 2, md: 4 }}>
                  <Grid item xs={12} md={6}>
                    <Paper className={classes.paperClass} sx={{ p: "20px" }}>
                      <Stack
                        spacing={2}
                        direction="column"
                        sx={{ mb: 1 }}
                        alignItems="flex-start"
                      >
                        <Typography
                          variant="h6"
                          component="h6"
                          // textAlign="left"
                          sx={{
                            opacity: "0.8",
                            textAlign: { xs: "left", md: "left" },
                          }}
                        >
                          Set your wheel settings
                        </Typography>
                        <Box
                          sx={{
                            padding: { xs: "10px", sm: "20px" },
                            width: "100%",
                            display: "block",
                          }}
                        >
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={6}>
                              <Typography
                                variant="subtitle2"
                                // textAlign="left"
                                sx={{
                                  textAlign: { xs: "left", md: "left" },
                                }}
                              >
                                Speed
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <PrettoSlider
                                valueLabelDisplay="auto"
                                aria-label="speed"
                                name="speed"
                                defaultValue={60}
                                value={SpeedValue}
                                onChange={(_, value: any) =>
                                  setSpeedValue(value)
                                }
                                min={60}
                                max={120}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              mt: {
                                xs: "6px !important",
                                md: "10px !important",
                              },
                            }}
                            spacing={2}
                            alignItems="center"
                          >
                            <Grid item xs={12} md={6}>
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  textAlign: { xs: "left", md: "left" },
                                }}
                              >
                                Decelaration
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <PrettoSlider
                                valueLabelDisplay="auto"
                                aria-label="deceleration"
                                defaultValue={3}
                                name="deceleration"
                                value={InputValue}
                                min={3}
                                onChange={(_, value: any) =>
                                  setInputValue(value)
                                }
                                max={7}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Stack>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className={classes.paperClass}>
                      <img src={trophy} alt="trophy"></img>
                      <Typography variant="h5" textAlign="center">
                        Win Chance{" "}
                      </Typography>
                      <br />
                      <Typography
                        textAlign="center"
                        sx={{
                          fontSize: { xs: "30px", md: "40px !important" },
                          fontWeight: "bold",
                        }}
                      >
                        1 :{" "}
                        {lotteryData?.numOfTicketOnSale &&
                        formValues.no_of_token > 0
                          ? Math.round(
                              lotteryData?.numOfTicketOnSale /
                                (formValues.no_of_token + totalSoldTicket)
                            )
                          : lotteryData?.numOfTicketOnSale}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          lineHeight: 0.334,
                          justifyContent: "center",
                          display: { md: "flex", xs: "block" },
                          alignItems: "center",
                          gap: "10px",
                        }}
                        textAlign="center"
                      >
                        <p
                          className=" m-auto"
                          style={{ display: "block", lineHeight: 1.5 }}
                        >
                          Previous Bought tickets :
                        </p>
                        <p>
                          {/* {totalSoldTicket} */}
                          <CurrencyFormat
                            value={totalSoldTicket}
                            thousandSeparator={","}
                            displayType={"text"}
                            decimalSeparator={"."}
                          />
                        </p>
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Paper
                      className={classes.paperClass}
                      sx={{
                        minHeight: { xs: 69, md: 210 },
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ flexFlow: "row wrap" }}
                      >
                        <Grid item xs={12} sm={8}>
                          <Grid
                            container
                            spacing={2}
                            className={classes.ticketPrize}
                          >
                            <Grid item xs={8} md={8}>
                              {" "}
                              <Typography variant="subtitle2" textAlign="left">
                                Ticket Price{" "}
                                <Box
                                  sx={{
                                    fontSize: { md: "20px", xs: "10px" },
                                    fontWeight: "700",
                                  }}
                                >
                                  {" "}
                                  ({formValues.no_of_token} Tickets *{" "}
                                  {lotteryData?.ticketPrice})
                                </Box>{" "}
                              </Typography>
                            </Grid>

                            <Grid item xs={4} md={4}>
                              <Typography
                                variant="h4"
                                sx={{ fontWeight: "700" }}
                                textAlign="right"
                              >
                                {totalAmount} USDT
                                {/* <CurrencyFormat
                                    value={totalAmount}
                                    thousandSeparator={"."}
                                    displayType={"text"}
                                    decimalSeparator={","}
                                  />{" "} */}
                              </Typography>
                            </Grid>

                            {jackpotSelected && (
                              <>
                                <Grid
                                  item
                                  xs={8}
                                  md={8}
                                  sx={{ marginTop: "20px" }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    textAlign="left"
                                  >
                                    Jackpot Price
                                    <Box
                                      sx={{
                                        fontSize: {
                                          md: "20px",
                                          xs: "10px",
                                        },
                                        fontWeight: "700",
                                      }}
                                    >
                                      ({formValues.no_of_token} Tickets * 0.5)
                                    </Box>
                                  </Typography>
                                </Grid>

                                <Grid item xs={4} md={4}>
                                  <Typography
                                    variant="h4"
                                    sx={{
                                      fontWeight: "700",
                                      marginTop: "20px",
                                      whiteSpace: "nowrap",
                                    }}
                                    textAlign="right"
                                  >
                                    {Number(jackpotAmount).toFixed(1)} G2F
                                  </Typography>
                                </Grid>
                              </>
                            )}
                            {
                              redeemCode && freeTicketAmt &&(
                                <>
                                <Grid
                                  item
                                  xs={8}
                                  md={8}
                                  sx={{ marginTop: "20px" }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    textAlign="left"
                                  >
                                    Coupon Prize Free Tickets
                                  </Typography>
                                </Grid>
  
                                <Grid item xs={4} md={4}>
                                  <Typography
                                    variant="h4"
                                    sx={{
                                      fontWeight: "700",
                                      marginTop: "20px",
                                      whiteSpace: "nowrap",
                                    }}
                                    textAlign="right"
                                  >
                                    {Number(freeTicketAmt)} 
                                  </Typography>
                                </Grid>
                             
                              <Grid item md={6} xs={6}>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    backgroundColor: "#3C37FF",
                                    borderRadius: "5px",
                                    padding: "5px",
                                  }}
                                >
                                  Free Ticket Coupon
                                </Typography>
                              </Grid>
                              <Grid item md={6} xs={6}>
                                <TextField
                                  sx={{
                                    width: "100%",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    textAlign: "center",
                                    "& .MuiInputBase-input": {
                                      padding: "6px 10px !important",
                                    },
                                  }}
                                  placeholder="Coupon Code"
                                  name="redeemCode"
                                  value={formValues?.redeemCode}
                                />
                              </Grid>
                              </>
                              )
                            }

                      
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={"auto"}>
                          {isLoading ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CircularProgress size={40} />
                            </Box>
                          ) : (
                            <Button
                              variant="contained"
                              sx={{
                                p: { md: "14px 28px", xs: "10px 10px" },
                              }}
                              type="submit"
                              disabled={isLoading}
                              onClick={handleFormSubmit}
                            >
                              Buy Tickets
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mt: { xs: "15px", md: "25px" } }}>
            <Notes />
          </Box>
          <StaticFAQ />
        </div>
      </Box>
    </Box>
  );
}
