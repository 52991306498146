import { useQuery, useQueryClient } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { friendsList } from '../types';
type FriendsListResponse  = {
  data: friendsList[];
  currentPage: number;
  totalPages: number;
  totalCount: number;
};
export const getFriendsList = async (page: number,
  limit: number): Promise<FriendsListResponse> => {
  const response:any = await axios.get(`/api/v1/user/referfriend`, {
    params: { page, limit },
  });
  //  const data = response.data;
  console.log(response , "abhay ")
  return response;
};

// type QueryFnType = typeof getFriendsList;

// type UseFriendsListOptions = {
//   page: number;
//   limit: number;
//   config?: QueryConfig<QueryFnType>;
// };
type UseFriendsListOptions = {
  page: number;
  limit: number;
};
// export const useFriendsList = ({

//   page,
//   limit,
//   config,
// }: UseFriendsListOptions) => {
//   return useQuery<ExtractFnReturnType<QueryFnType>>({
//     queryKey: ['friendsList', page, limit], // Include page and limit in query key
//     queryFn: () => getFriendsList(page, limit),
//     keepPreviousData: true, // Enables smooth pagination
//     ...config,
//   });
// };

export const useFriendsList = ({ page, limit }: UseFriendsListOptions) => {
  const queryClient = useQueryClient();
  return useQuery<any>({
    queryKey: ['friendsList', page, limit],
    queryFn: () => getFriendsList(page, limit),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });
};