import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  FormControl,
  InputLabel,
  Stack,
  Typography,
  Divider,
  CardMedia,
  Button,
  Icon,
  InputAdornment,
  TextField,
  Menu,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";

import { Grid, Select, MenuItem } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { theme } from "@/components/Elements/theme";
import { MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
import { MantineProvider } from "@mantine/core";
import { Title } from "@material-ui/icons";
import { DatePickerInput } from "@mantine/dates";
import { useAuth } from "@/lib/auth";
import { getFriendsList, useFriendsList } from "../api/getFriendList";
import { getPromotionList } from "../api/getPromotionList";
import { postFriendsRefer } from "../api/postReferfriend";
import "./table.css";
import { friendsList, Payment } from "../types";
import "./table.css";
import Swal from "sweetalert2";
import { SelectChangeEvent } from "@mui/material/Select";
import { useTable } from "react-table";
import Pagination from "rc-pagination";
//nested data is ok, see accessorKeys in ColumnDef below

type letterValues = {
  email: string;
  coupanId: string;
  couponCode: any;
};

const initialValues = {
  email: "",
  coupanId: "",
  couponCode: "",
};
interface Promotion {
  _id: string;
  rewardBy: string;
  rewardAmount: number;
  couponCode: string;
  targetUser: number;
  sentNumTargetUser: number;
  repeatReward: boolean;
  usesPerCoupon: string;
  comments: string;
  status: boolean;
  minimumCartValue: string;
  discountType: string;
  usesPerPlayer: number;
  promotionName: string;
  promotionDetails: string;
  image: string;
  lotteryId: string;
  usedPromotionUser: string[];
  startDateTime: string;
  endDateTime: string;
  createdAt: string;
  updatedAt: string;
}

export const Friends = () => {
  const [page, setPage] = useState(1);
  const limit = 5; // Set a fixed limit per page

  const { user } = useAuth();
  const { data, isLoading, isError } = useFriendsList({
    page,
    limit,
  });
  console.log(isLoading, "isLoading");
  const updatePage = (newPage: number) => {
    setPage(newPage); // Update the current page
  };

  const [promotionList, setPromotionList] = useState<Promotion[]>([]);
  const [selectedCoupon, setSelectedCoupon] = useState("select");
  const [data1, setData] = useState("");
  const [formValues, setFormValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  console.log("loading", loading);
  const Item = styled(Paper)(({ theme }) => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));

  var cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    height: "auto",
    background: "#050518",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
  };

  useEffect(() => {
    promotionList1();
  }, [data1]);
  const promotionList1 = async () => {
    const PromotionsQuery = await getPromotionList();
    setPromotionList(PromotionsQuery);
  };
  const handleSimple = (e: SelectChangeEvent<string>) => {
    const selectedId = e.target.value;
    const selectedPromotion = promotionList.find(
      (item) => item._id === selectedId
    );

    if (selectedPromotion) {
      setFormValues({
        ...formValues,
        coupanId: selectedId, // Save the selected _id
        couponCode: selectedPromotion.couponCode, // Save the coupon code
      });
    }

    setSelectedCoupon(selectedId); // Set the selected value
  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  // const handleFormSubmit = (e: React.FormEvent) => {
  //   e.preventDefault();
  //   let data = { email: formValues.email };

  //   try {
  //     setLoading(true);
  //     postFriendsRefer(formValues).then((res) => {
  //       setData(res);
  //     });
  //     setLoading(true);
  //     setFormValues({
  //       email: "",
  //       coupanId: "",
  //       couponCode: "",
  //     });
  //     setSelectedCoupon("select");
  //   } catch {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Friend Refer not sent!",
  //     });
  //   }finally {
  //       setLoading(false);
  //      }
  // };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setLoading(true); 

      
      postFriendsRefer(formValues)
        .then((res) => {
          setData(res);
          setFormValues({
            email: "",
            coupanId: "",
            couponCode: "",
          });
          setSelectedCoupon("select");
        })
        .catch((err) => {
          console.log(err);
          // Swal.fire({
          //   icon: "error",
          //   title: "Friend Refer not sent!",
          // });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log(error)
      // Swal.fire({
      //   icon: "error",
      //   title: "Unexpected error!",
      // });
      setLoading(false);
    }
  };

  var center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  var content = {
    color: "#ffff",
    backgroundColor: "#050518",
    borderRadius: "12px",
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Registration",
        accessor: (row) =>
          row?.createdAt
            ? new Date(row?.createdAt)?.toISOString().split("T")[0] +
            " " +
            new Date(row?.createdAt)
              ?.toISOString()
              .split("T")[1]
              .substring(0, 5)
            : "",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <Box
            sx={(theme) => ({
              color:
                value === "Done"
                  ? "#95D23D"
                  : value === "pending"
                    ? "#FF9900"
                    : "#FF4545",
              borderRadius: "4px",
              border:
                value === "Done"
                  ? "1px solid rgba(149, 210, 61, 0.41)"
                  : value === "pending"
                    ? "1px solid rgba(231, 214, 61, 0.5)"
                    : "1px solid rgba(255, 69, 69, 0.4)",
              backgroundColor:
                value === "Done"
                  ? "rgba(149, 210, 61, 0.19)"
                  : value === "pending"
                    ? "rgba(231, 214, 61, 0.1)"
                    : "rgba(255, 69, 69, 0.05)",
              maxWidth: "fit-content",
              padding: "4px",
            })}
          >
            {value.charAt(0).toUpperCase() + value.slice(1)}
          </Box>
        ),
      },
    ],
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: data?.data || [],
    });

  // const columns = useMemo<MRT_ColumnDef<friendsList>[]>(
  //   () => [
  //     {
  //       accessorKey: "email",
  //       header: "Email",
  //       size: 100,
  //       //custom conditional format and styAling
  //     },

  //     {
  //       accessorKey: "amount",
  //       header: "Amount",
  //       size: 100,
  //       //custom conditional format and styling
  //     },
  //     {
  //       accessorFn: (row) =>
  //         row?.createdAt
  //           ? new Date(row?.createdAt)?.toISOString().split("T")[0] +
  //             " " +
  //             new Date(row?.createdAt)
  //               ?.toISOString()
  //               .split("T")[1]
  //               .substring(0, 5)
  //           : "",
  //       header: "Registration",
  //       size: 100,
  //       //custom conditional format and styling
  //     },

  //     {
  //       accessorKey: "status", //hey a simple column for once
  //       header: "Status",
  //       size: 100,
  //       Cell: ({ cell }) => (
  //         <Box
  //           sx={(theme) => ({
  //             color:
  //               cell.getValue<string>() === "Approved"
  //                 ? "#95D23D"
  //                 : cell.getValue<string>() === "Pending"
  //                 ? "#FF9900"
  //                 : "#FF4545",
  //             borderRadius: "4px",
  //             border:
  //               cell.getValue<string>() === "Approved"
  //                 ? "1px solid rgba(149, 210, 61, 0.41)"
  //                 : cell.getValue<string>() === "Pending"
  //                 ? "1px solid rgba(231, 214, 61, 0.5)"
  //                 : "1px solid rgba(255, 69, 69, 0.4)",
  //             backgroundColor:
  //               cell.getValue<string>() === "Approved"
  //                 ? "rgba(149, 210, 61, 0.19)"
  //                 : cell.getValue<string>() === "Pending"
  //                 ? "rgba(231, 214, 61, 0.1)"
  //                 : "rgba(255, 69, 69, 0.05)",
  //             maxWidth: "9ch",
  //             padding: "4px",
  //           })}
  //         >
  //           {cell.getValue<string>()}
  //         </Box>
  //       ),
  //     },
  //   ],
  //   []
  // );

  return (
    <Box sx={{ height: "auto" }}>
      <Grid container sx={{ mt: "100px" }}>
        <Grid item xs={12} md={12}>
          {" "}
          <Card style={cardStyle} sx={{ padding: " 19.2px", margin: "12.8px" }}>
            <CardContent>
              <Stack spacing={2}>
                <Item>
                  {" "}
                  <Typography variant="h5">Friends Referral</Typography>
                </Item>
                <Item sx={{ width: "100%" }}>
                  <Grid item xs={12} md={12}>
                    <Item
                      sx={{
                        display: { xs: "flex" },
                        flexDirection: { xs: "column", md: "row" },
                      }}
                    >
                      {" "}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        sx={{ pt: "26px", width: { xs: "100%" } }}
                      >
                        <FormControl fullWidth={true}>
                          <InputLabel
                            htmlFor="email"
                            shrink={true}
                            sx={{ color: "#FFFFFF" }}
                          >
                            Email Address
                          </InputLabel>
                          <TextField
                            margin="normal"
                            required
                            id="email"
                            autoFocus
                            name="email"
                            onChange={handleChange}
                            value={formValues.email}
                            placeholder="Enter Friend Email Id"
                            inputProps={{
                              inputMode: "text", // Ensures the text keyboard is displayed on mobile
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={4} md={3} sx={{ pt: "40px" }}>

                        <FormControl fullWidth sx={{ minWidth: "256px" }}>
                          <Select
                            id="couponId"
                            name="couponId"
                            label="Select Coupon"
                            input={
                              <OutlinedInput
                                className={"disabledValue"}
                                notched
                                label={"Select Coupon"}
                              />
                            }
                            value={selectedCoupon}
                            onChange={handleSimple}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  bgcolor: "black",
                                  "& .MuiMenuItem-root": {
                                    padding: 1,
                                    fontFamily: "Ubuntu",
                                    "font-style": "normal",
                                    "font-weight": 400,
                                    fontSize: "14px",
                                    minWidth: "256px",
                                  },
                                },
                              },
                            }}
                          >
                            {promotionList && promotionList.length > 0 ? (
                              promotionList.map((coupon) => (
                                <MenuItem key={coupon._id} value={coupon._id}>
                                  {coupon.promotionName}{" "}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>No offer available</MenuItem>
                            )}
                          </Select>
                        </FormControl>

                      </Grid>
                      {loading ? (
                        <div style={{ marginTop: "55px" }}>
                          <CircularProgress size={24} />
                        </div>
                      ) : (
                        <Grid item xs={12} sm={12} md={2} sx={{ pt: 5 }}>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ p: "10px 39px" }}
                            onClick={handleFormSubmit}
                          >
                            Send
                          </Button>
                        </Grid>
                      )}
                    </Item>
                  </Grid>
                </Item>
                {isLoading ? (
                 <div
                 style={{
                   width: "100%",
                   display: "flex",
                   alignItems: "center",
                   justifyContent: "center",
                 }}
               >
                 <CircularProgress />
               </div>
                ): (<Item>
                  {/* <Grid item sx={{ width: "100%", overflowX: "auto" }}>
                    <MantineProvider
                      withGlobalStyles
                      withNormalizeCSS
                      theme={{
                        breakpoints: {
                          xs: "30em",
                          sm: "48em",
                          md: "64em",
                          lg: "74em",
                          xl: "90em",
                        },
                        fontSizes: { xs: "12px", md: "14px" },
                        colorScheme: "dark",
                      }}
                    >
                      {FriendsQuery?.data ? (
                        <MantineReactTable
                          columns={columns}
                          data={FriendsQuery.data || []}
                          positionPagination="top"
                          initialState={{ showColumnFilters: true }}
                          positionToolbarAlertBanner="bottom"
                        />
                      ) : (
                        <div className="circular-progress1">
                          <div className="circle"></div>
                          <div className="mask full">
                            <div className="fill"></div>
                          </div>
                          <div className="mask half">
                            <div className="fill"></div>
                            <div className="fill fix"></div>
                          </div>
                        </div>
                      )}
                    </MantineProvider>
                  </Grid> */}

                  <Grid item sx={{ width: "100%", overflowX: "auto" }}>
                  <div {...getTableProps()}>
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        color: "white",
                        backgroundColor: "#333",
                        border: "2px solid #FFF",
                        borderRadius: "4px",
                      }}
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps()}
                                style={{
                                  padding: "8px",
                                  borderBottom: "2px solid #FFF",
                                  color: "white",
                                  textAlign: "left",
                                }}
                              >
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    style={{
                                      padding: "8px",
                                      borderBottom: "1px solid #FFF",
                                    }}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Grid>
              </Item>)}
              {isLoading ? (
                 <div>
               </div>
                ): (
                  <Pagination
                  pageSize={limit}
                  current={page}
                  total={data?.totalCount || 0}
                  onChange={updatePage}
                />
                )}
             
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    </Box >
  );
};
